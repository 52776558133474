export const Loading = () => {

    return (
        <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-80 flex items-center justify-center z-[1000]">
            <div className="text-center">
                <div className="animate-spin rounded-full h-32 w-32 border-t-4 border-[#eae2f8] border-solid"></div>
                <p className="mt-8 text-2xl text-white">Loading, please wait...</p>
            </div>
        </div>
    );
}