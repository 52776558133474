import React, {useEffect, useState }   from "react";
import { Header } from "../components/Header";
import { Loading } from "../components/Loading";
import config from '../config';

export const ExpenseOverview = () => {
    const [groups, setGroups] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const [totalUserOwes, setTotalUserOwes] = useState(0); // For "YOU CURRENTLY OWE"
    const [totalUserIsOwed, setTotalUserIsOwed] = useState(0); // For "YOU ARE OWED"

    const [debts, setDebts] = useState([]);
    const [credits, setCredits] = useState([]);

    const processedGroupIds = new Set();

    const addDebt = (group, amountOwed) => {
        setDebts(prevDebts => {
            return [...prevDebts, [group, amountOwed]];
        });
    };

    const addCredit = (group, amountOwed) => {
        setCredits(prevCredits => {
            return [...prevCredits, [group, amountOwed]];
        });
    };

    useEffect(() => {
        const fetchGroups = async () => {
            setLoading(true);
            try {
                const token = localStorage.getItem('idToken');
                const userId = localStorage.getItem('id');

                // Fetch the total amount owed
                const debtResponse = await fetch(`${config.apiBaseUrl}/api/expenses/getNetDebt?userId=${userId}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                });

                if (debtResponse.ok) {
                    const debtAmount = await debtResponse.json();
                    setTotalUserOwes(debtAmount);
                }

                // Fetch the total amount owed to user
                const owedResponse = await fetch(`${config.apiBaseUrl}/api/expenses/getNetOwed?userId=${userId}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                });

                if (owedResponse.ok) {
                    const owedAmount = await owedResponse.json();
                    setTotalUserIsOwed(owedAmount);
                }

                // Fetch groups the user is in
                const groupResponse = await fetch(`${config.apiBaseUrl}/api/usergroups/user?userId=${userId}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                });

                if (!groupResponse.ok) {
                    throw new Error('Failed to fetch groups');
                }

                const groupsData = await groupResponse.json();
                setGroups(groupsData);

                await fetchExpenses(groupsData, token)
            } catch (error) {
                setError(error.message);
            }
        };

        const fetchExpenses  = async (groups, token) => {
            try {
                if (groups.length > 0) {
                    const groupExpensesPromises = groups.map(async (group) => {
                        const expensesResponse = await fetch(`${config.apiBaseUrl}/api/expenses/getGroupExpenses?groupId=${group.id}`, {
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': `Bearer ${token}`
                            }
                        });

                        if (!expensesResponse.ok) {
                            throw new Error(`Failed to fetch expenses for group ${group.group_name}`);
                        }

                        const expenses = await expensesResponse.json();
                        return {groupId: group.id, expenses: expenses.map(expense => expense.expense_id), group_name: group.group_name};
                    });

                    const groupExpensesData = await Promise.all(groupExpensesPromises);

                    const expensesMap = {};


                    groupExpensesData.forEach (({ groupId, expenses, group_name}) => {
                        if (!processedGroupIds.has(groupId)) {
                            processedGroupIds.add(groupId);
                            expensesMap[groupId] = {
                                expenses: expenses,
                                groupName: group_name
                            };
                        }
                    });

                    const expenseInfoPromises = [];

                    for (const [groupId, { expenses, groupName }] of Object.entries(expensesMap)) {
                        if (!expenses || expenses.length === 0) {
                            continue;
                        }

                        expenses.forEach((expense) => {
                            const fetchEachExpense = async () => {
                                try {
                                    const expenseResponse = await fetch(`${config.apiBaseUrl}/api/expenses/getExpense?expenseId=${expense}`, {
                                        method: 'GET',
                                        headers: {
                                            'Content-Type': 'application/json',
                                            'Authorization': `Bearer ${token}`
                                        }
                                    });

                                    if (!expenseResponse.ok) {
                                        throw new Error(`Failed to fetch expense ${expense} from group id = ${groupId}`);
                                    }

                                    const expenseInfo = await expenseResponse.json();
                                    calculateUserDebts(expenseInfo, groupName);
                                    calculateUserCredits(expenseInfo, groupName);
                                } catch (error) {
                                    setError(error.message);
                                }
                            };

                            expenseInfoPromises.push(fetchEachExpense);
                        });
                    }

                    const allCalculatedResults = await Promise.all(expenseInfoPromises.map(fn => fn()));
                }
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchGroups();
    }, []);

    const calculateUserDebts = async (expenseInfo, groupName) => {
        expenseInfo.user_expenses.forEach(userExpense => {
            if (userExpense.name === localStorage.getItem('username')) {
                if (userExpense.amount_owed > 0) {
                    addDebt(groupName, userExpense.amount_owed)
                }

            }
        })
    };

    const calculateUserCredits = async (expenseInfo, groupName) => {
        if (expenseInfo.owner.name === localStorage.getItem('username')) {
            if (expenseInfo.total_owed > 0) {
                addCredit(groupName, expenseInfo.total_owed);
            }
        }
    };

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <div className="relative bg-[#f6f6f6] min-h-screen flex flex-col items-center">
            {/* Header */}
            <Header className="w-full" />

            {loading && (
                <Loading />
            )}  

            {/* Wrapper with max-width and padding */}
            <div className="w-full max-w-screen-xl px-4 "> {/* Ensure margin to avoid overlap */}
                {/* Expense Overview Section */}
                <div className="mt-4 md:mt-16 p-4 md:p-8 bg-white rounded-2xl shadow-lg flex flex-col items-center space-y-8">
                    {/* Debt and Credit */}
                    <div className="flex flex-col md:flex-row justify-between items-center w-full space-y-8 md:space-y-0">
                        <div className="text-center">
                            <div className="text-1xl font-semibold text-[#262526] ml-4 md:ml-8 lg:ml-40">YOUR DEBT</div>
                            <div className="text-7xl md:text-7xl text-[#d50808] font-mono ml-4 md:ml-8 lg:ml-40">R{Number(totalUserOwes).toFixed(2)}</div>
                            {/* debts */}
                            <div className="flex flex-col w-full space-y-4">
                                {debts.map((debt, index) => {
                                    const debtRatio = (debt[1]/totalUserOwes);

                                    return (
                                        <div key={index} className="flex items-center space-x-4">
                                            <div className="flex flex-col items-start min-w-[120px]">
                                                <span className="text-sm md:text-lg font-mono">R{Number(debt[1]).toFixed(2)}</span> {/* Debt amount */}
                                                <div className="text-xs text-[#5b5b5b] font-sans">{debt[0]}</div>          {/* Group */}
                                            </div>
                                            <div
                                                className="flex-shrink-0 h-4 bg-[#d50808] rounded-full w-40 md:w-60"
                                                style={{
                                                    width: `calc(${debtRatio * 300}px)`,         // Mobile width
                                                    '@media (minWidth: 768px)': {
                                                        width: `calc(${debtRatio * 50}px)`       // Desktop width
                                                    }
                                                }}
                                            ></div> {/* Bar width */}
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        <div className="text-center">
                            <div className="text-1xl font-semibold text-[#262526] mr-4 md:mr-8 lg:mr-40">YOUR CREDIT</div>
                            <div className="text-7xl md:text-7xl text-[#0bc20b] font-mono mr-4 md:mr-8 lg:mr-40">R{Number(totalUserIsOwed).toFixed(2)}</div>
                            {/* credits */}
                            <div className="flex flex-col w-full space-y-4">
                                {credits.map((credit, index) => {
                                    const creditRatio = (credit[1]/totalUserIsOwed);

                                    return (
                                        <div key={index} className="flex items-center space-x-4">
                                            <div className="flex flex-col items-start min-w-[120px]">
                                                <span className="text-sm md:text-lg font-mono">R{Number(credit[1]).toFixed(2)}</span> {/* Credit amount */}
                                                <div className="text-xs text-[#5b5b5b] font-sans">{credit[0]}</div>          {/* Group */}
                                            </div>
                                            <div
                                                className="flex-shrink-0 h-4 bg-[#0bc20b] rounded-full w-40 md:w-60"
                                                style={{
                                                    width: `calc(${creditRatio * 300}px)`,         // Mobile width
                                                    '@media (minWidth: 768px)': {
                                                        width: `calc(${creditRatio * 50}px)`       // Desktop width
                                                    }
                                                }}
                                            ></div> {/* Bar width */}
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-col md:flex-row justify-between items-center w-full space-y-8 md:space-y-0">
                        

                        
                    </div>

                    {/* Balance */}
                    <div className="w-full bg-gray-300 text-center p-6 rounded-lg">
                        <div className="text-lg md:text-2xl">you have a balance of</div>
                        <div className="text-3xl md:text-5xl font-mono text-[#262526] underline">R{Number(totalUserIsOwed-totalUserOwes).toFixed(2)}</div>
                    </div>
                </div>
            </div>

        </div>
    );
};