import React, { useEffect, useState} from "react";
import { useNavigate } from 'react-router-dom';

export const FirstPage = () => {
    const navigate = useNavigate();
    const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 768);

    useEffect(() => {
        const handleResize = () => {
          setIsDesktop(window.innerWidth >= 768);
        };
    
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
      }, []);

    return (
        <div className="relative flex flex-col md:flex-row w-full h-screen min-h-[300px] md:min-h-[800px]">
            {/* Logo in the middle */}
            <img
                className="absolute w-1/2 max-w-lg left-1/2 transform -translate-x-1/2 top-[15%] md:top-[25%] -translate-y-1/2 z-50"
                alt="Logo"
                src="/YellowLogo.png"
            />

            {/* Left Section - Title and Login */}
            <div className="flex-1 bg-black flex flex-col items-center justify-center p-8 pr-4 relative">
                {/* Text on top */}
                <h2 className="hidden md:block md:absolute md:bottom-[35%] md:right-0 text-6xl text-[#f5f4b8] font-bold mt-[70px] mr-5 ml-auto">manage your</h2>

                <div className="flex flex-col relative md:h-screen md:flex-row md:w-full space-y-4 mt-[40%] md:mt-0 md:space-y-0 md:space-x-4 mt-8">
                    {/* Left Button - Login */}
                    <button
                        className={`md:absolute md:bottom-[17%] md:right-5 px-12 py-3 bg-white text-black rounded-md shadow hover:bg-[#eae2f8] font-sans md:ml-auto bottom-[10%]`}
                        onClick={() => navigate('/login')}
                    >
                        LOGIN
                    </button>
                    <button
                        className={`md:hidden block px-12 py-3 bg-white text-black rounded-md shadow hover:bg-[#eae2f8] font-sans`}
                        onClick={() => navigate('/signup')}
                    >
                        SIGN UP
                    </button>
                </div>
            </div>

            {/* Right Section - Title and Sign Up */}
            <div className="flex-1 bg-white flex flex-col justify-center items-center p-8 pl-2 relative">
                {/* Text on top */}
                <h2 className="md:hidden block text-6xl text-[#f5f4b8] font-bold mt-[70px]">manage your</h2>
                <h2 className="md:absolute md:bottom-[33%] md:left-0 lg:text-8xl text-6xl font-heading text-black font-extrabold ml-3 mt-4 text-center md:text-left">GROUP EXPENSES</h2>

                <div className="flex flex-col relative md:h-screen md:flex-row md:w-full space-y-4 mt-[40%] md:mt-0 md:space-y-0 md:space-x-4 mt-">
                    {/* Right Button - Sign Up */}
                    <button
                        className="md:adsolute md:bottom-[17%] md:left-5 hidden md:block px-12 py-3 bg-black text-white rounded-md shadow hover:bg-[#eae2f8] hover:text-black absolute bottom-1/4 font-sans"
                        onClick={() => navigate('/signup')}
                    >
                        SIGN UP
                    </button>
                </div>
            </div>
        </div>
    );
};
