import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { Menu, LayoutDashboard, Users, HandCoins } from 'lucide-react';
import { MenuComponent } from "./Menu";

export const Header = () => {
    const [isNavOpen, setIsNavOpen] = useState(false);
    const navigate = useNavigate();
    const username = localStorage.getItem('username');

    const toggleNav = () => {
        setIsNavOpen(!isNavOpen);
    };

    const handleNavigation = (path) => {
        navigate(path);
        setIsNavOpen(false);
    };

    return (
        <div className="sticky top-0 z-50 bg-black bg-opacity-90 h-24 w-full flex items-center justify-between px-4 relative"> {/* Added 'sticky', 'top-0', and 'bg-opacity-80' */}
            {/* Menu Button */}
            <button onClick={toggleNav} className="ml-4">
                <Menu size={48} color="#eae2f8" />
            </button>

            {/* Profile Section */}
            <div className="flex items-center justify-end space-x-4 absolute right-8">
                <div onClick={() => handleNavigation('/profile')} className="text-white text-xl font-semibold cursor-pointer font-sans">
                    {username}
                </div>
                <img className="w-16 h-16 rounded-full cursor-pointer" src="/avatar.png" alt="Avatar" onClick={() => handleNavigation('/profile')} />
            </div>

            {/* Logo */}
            <img className="absolute left-1/2 transform -translate-x-1/2 h-16 object-contain cursor-pointer" alt="Logo" src="/YellowLogo.png" onClick={() => handleNavigation('/dashboard')}/>

            {/* Navigation Pane */}
            <div className={`fixed top-0 left-0 h-full w-64 bg-black transform  ${isNavOpen ? "translate-x-0 shadow-5xl" : "-translate-x-full"} transition-transform duration-300 ease-in-out z-20` }>
                <div className="p-6">
                    {/* Close Button */}
                    <button onClick={toggleNav} className="mt-2 ml-4">
                        <Menu size={48} color="#eae2f8" />
                    </button>

                    {/* Navigation Links */}
                    <nav className="flex flex-col space-y-2">
                        <button onClick={() => handleNavigation('/dashboard')} className="flex items-center justify-left text-white text-lg mt-6 h-10 rounded hover:bg-[#eae2f8] hover:text-black font-sans transition transform duration-200 hover:scale-110">
                            <LayoutDashboard className="mr-2 ml-4" />
                            DASHBOARD
                        </button>
                        <button onClick={() => handleNavigation('/groups')} className="flex items-center justify-left text-white text-lg h-10 rounded hover:bg-[#eae2f8] hover:text-black font-sans transition transform duration-200 hover:scale-110">
                            <Users className="mr-2 ml-4" />
                            GROUPS
                        </button>
                        <button onClick={() => handleNavigation('/expense-overview')} className="flex items-center justify-left text-white text-lg h-10 rounded hover:bg-[#eae2f8] hover:text-black font-sans transition transform duration-200 hover:scale-110">
                            <HandCoins className="mr-2 ml-4" />
                            EXPENSES
                        </button>
                    </nav>
                </div>

                {/* Sign Out */}
                <div className="absolute bottom-4 left-4">
                    <button onClick={() => handleNavigation('/')} className="flex items-center text-white text-lg hover:underline font-sans transition transform duration-200 hover:scale-110">
                        <span className="mr-2">↩️</span> sign out
                    </button>
                </div>
            </div>

            {/* Background overlay when the nav pane is open */}
            {isNavOpen && (
                <div
                    className="fixed inset-0 bg-transparent z-10"
                    onClick={toggleNav}
                ></div>
            )}
        </div>
    );
};

