import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Header} from "../components/Header";
import { Loading } from "../components/Loading";
import {AiOutlinePlus} from "react-icons/ai"; // Import the Plus Icon
import { MoveRight } from 'lucide-react';
import {CreateGroup} from "../components/CreateGroup"; // Import the modal component
import '../App.css';
import config from '../config';

export const Dashboard = () => {
        const navigate = useNavigate();
        const [groups, setGroups] = useState([]);
        const [loading, setLoading] = useState(true);
        const [error, setError] = useState(null);
        const [selectedGroupMembers, setSelectedGroupMembers] = useState({});
        const [selectedGroupExpenses, setSelectedGroupExpenses] = useState({});
        const [isModalOpen, setIsModalOpen] = useState(false); // Modal state
        const [totalUserOwes, setTotalUserOwes] = useState(0); // For "YOU CURRENTLY OWE"
        const [totalUserIsOwed, setTotalUserIsOwed] = useState(0); // For "YOU ARE OWED"

    // Define the function here
    const fetchGroupsAndMembers = async () => {
        try {
            setLoading(true);
            const token = localStorage.getItem('idToken');
            const userId = localStorage.getItem('id');

            // Fetch total debt
            const debtResponse = await fetch(`${config.apiBaseUrl}/api/expenses/getNetDebt?userId=${userId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            if (debtResponse.ok) {
                const debtAmount = await debtResponse.json();
                setTotalUserOwes(debtAmount);
            }

            // Fetch total owed to user
            const owedResponse = await fetch(`${config.apiBaseUrl}/api/expenses/getNetOwed?userId=${userId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });
            if (owedResponse.ok) {
                const owedAmount = await owedResponse.json();
                setTotalUserIsOwed(owedAmount);
            }

            // Fetch groups
            const groupResponse = await fetch(`${config.apiBaseUrl}/api/usergroups/user?userId=${userId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });

            if (!groupResponse.ok) {
                throw new Error('Failed to fetch groups');
            }

            const groupsData1 = await groupResponse.json();
            const groupsData = groupsData1.reverse(); // Reverse the order
            const limitedGroups = groupsData.slice(0, 3); // Limit to 3 groups
            setGroups(limitedGroups);

            // Fetch group members and expenses for each group
            const groupMembersPromises = limitedGroups.map(async (group) => {
                // Fetch group members
                const membersResponse = await fetch(`${config.apiBaseUrl}/api/usergroups/group?groupId=${group.id}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                });

                if (!membersResponse.ok) {
                    throw new Error(`Failed to fetch members for group ${group.group_name}`);
                }

                const members = await membersResponse.json();

                // Fetch expenses for the group
                const expensesResponse = await fetch(`${config.apiBaseUrl}/api/expenses/getGroupExpenses?groupId=${group.id}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                });

                const groupExpenses = await expensesResponse.json();
                const detailedExpenses = [];

                // Fetch detailed information for each expense (limit to first 2)
                for (let i = 0; i < Math.min(9, groupExpenses.length); i++) {
                    const expense = groupExpenses[i];

                    const expenseDetailResponse = await fetch(`${config.apiBaseUrl}/api/expenses/getExpense?expenseId=${expense.expense_id}`, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`
                        }
                    });

                    if (expenseDetailResponse.ok) {
                        const expenseDetail = await expenseDetailResponse.json();
                        detailedExpenses.push(expenseDetail);
                    }
                }

                return {
                    groupId: group.id,
                    members: members.map(member => member.username),
                    expenses: detailedExpenses // Store detailed expenses for each group
                };
            });

            const groupMembersData = await Promise.all(groupMembersPromises);

            const membersMap = {};
            const expensesMap = {};

            groupMembersData.forEach(({ groupId, members, expenses }) => {
                membersMap[groupId] = members;
                expensesMap[groupId] = expenses; // Map groupId to detailed expenses
            });

            setSelectedGroupMembers(membersMap);
            setSelectedGroupExpenses(expensesMap); // Store expenses

        } catch (error) {
            console.error('An error occurred:', error.message);
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        // Call fetchGroupsAndMembers in useEffect
        fetchGroupsAndMembers();
    }, []);



    const handleExpenseOverviewClick = () => {
            navigate("/expense-overview");
    };

    const handleGroupClick = (groupName, groupId) => {
        navigate(`/group/${groupName}`, { state: { groupName, groupId } });
    };

    const handleNavigation = (path) => {
        navigate(path);
    };

    const handleAddGroupClick = () => {
        setIsModalOpen(true);  // Open modal on click
    };

    const handleCreateGroup = (newGroup) => {
        // Add the new group to the state
        fetchGroupsAndMembers();
    };

        if (error) {
            return <div>Error: {error}</div>;
        }

        return (
            <div className="relative bg-gray-100 min-h-screen flex flex-col items-center">
                <Header className="w-full" />
                {loading && (
                    <Loading />
                )} 

                <div className="w-full max-w-screen-xl px-4 mt-8">
                    {/* Expense Overview Section */}
                    <div className="text-center rounded-xl overflow-hidden bg-white shadow-lg p-6 md:p-8">
                        <div className="flex flex-col md:flex-row justify-between items-center space-y-8 md:space-y-0">
                            <div className="text-center">
                                <div
                                    className="text-xl md:text-2xl ml-4 md:ml-8 lg:ml-40"
                                >YOU CURRENTLY OWE:</div>
                                <br />
                                <div className="text-red-600 text-5xl md:text-[100px] font-mono ml-4 md:ml-8 lg:ml-40">R{Math.floor(totalUserOwes)}</div>
                            </div>
                            <div className="text-center">
                                <div
                                    className="text-xl md:text-2xl mr-4 md:mr-8 lg:mr-40"
                                >YOU ARE OWED:</div>
                                <br />
                                <div className="text-green-600 text-5xl md:text-[100px] font-mono mr-4 md:mr-8 lg:mr-40">R{Math.floor(totalUserIsOwed)}</div>
                            </div>
                        </div>
                        <div className="flex justify-center mt-4">
                            <button
                                className="flex items-center px-8 py-3 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-[#eae2f8] text-lg font-sans"
                                onClick={handleExpenseOverviewClick}
                            >
                                view details
                                <MoveRight className="ml-4"/>
                            </button>
                        </div>
                    </div>

                    {/* Groups Section */}
                    <div className="text-center mt-8 p-4 md:p-8">
                        <div className="text-3xl md:text-4xl font-girassol text-center mb-8">GROUPS</div>
                        <div className="grid md:grid-cols-3 grid-cols-1 gap-6">
                            {/* Render group containers */}
                            {groups && groups.length > 0 && groups.map((group) => (
                                group ? (
                                    <div
                                        key={group.id}
                                        className="bg-gray-300 rounded-[40px] p-4 md:p-6 shadow-lg w-full cursor-pointer min-h-[500px] md:min-h-[350px]"
                                        onClick={() => handleGroupClick(group.group_name, group.id)} // Pass group name and ID
                                    >
                                        <div className="text-xl md:text-2xl font-bold font-girassol text-center">{group.group_name}</div>
                                        <p className="text-sm text-center mt-2 text-gray-600 font-sans">
                                            {selectedGroupMembers[group.id]
                                                ? selectedGroupMembers[group.id].join(", ")
                                                : "Loading members..."}
                                        </p>
                                        <hr className="mt-1"/>
                                        <div className="mt-4">
                                            {selectedGroupExpenses[group.id] ? (
                                                selectedGroupExpenses[group.id].map((expense, index) => (
                                                    <div key={index} className="text-sm mt-2">
                                                        <span className="font-semibold">R{expense.total_owed}</span> paid by {expense.owner.name} for {expense.expense.type}
                                                    </div>
                                                ))
                                            ) : (
                                                <p>Loading expenses...</p>
                                            )}
                                        </div>
                                    </div>
                                ) : null
                            ))}


                            {/* Show the "+" icon for adding a group based on group count */}
                            {groups.length < 3 && (
                                <div
                                    className="bg-gray-200 rounded-[40px] p-4 md:p-6 shadow-lg w-full cursor-pointer min-h-[500px] md:min-h-[350px] flex items-center justify-center"
                                    onClick={handleAddGroupClick}
                                >
                                    <AiOutlinePlus className="text-5xl text-gray-500" />
                                </div>
                            )}
                        </div>
                        <br />
                        <div className="flex justify-center mt-4">
                            <button
                                className="flex items-center px-8 py-3 bg-white rounded-md shadow-sm hover:bg-[#eae2f8] text-lg font-sans"
                                onClick={() => handleNavigation('/groups')}
                            >
                                view more
                                <MoveRight className="ml-4"/>
                            </button>
                        </div>
                    </div>
                </div>

                {/* Modal for creating a new group */}
                {isModalOpen && (
                    <CreateGroup
                        onClose={() => setIsModalOpen(false)}
                        onCreateGroup={handleCreateGroup}
                    />
                )}
            </div>
        );
    };
