import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import config from '../config';

export const Login = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const handleLogin = async () => {
        if (email && password) {
            try {
                const response = await fetch(`${config.apiBaseUrl}/api/auth/login`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        email,
                        password
                    })
                });

                const data = await response.json();

                if (response.ok) {
                    // Store the token and the email or username
                    //todo these can be accessed in other pages by e.g "localStorage.getItem('idToken')"
                    localStorage.setItem('idToken', data.idToken);
                    localStorage.setItem('username', data.username); // Store email or username
                    localStorage.setItem('id', data.id)
                    localStorage.setItem('email', data.email);

                    // Navigate to dashboard
                    navigate('/dashboard');
                } else {
                    setErrorMessage(data.message || 'Login failed');
                }
            } catch (error) {
                setErrorMessage('An error occurred. Please try again.');
            }
        } else {
            setErrorMessage('Please enter both email and password');
        }
    };


    return (
        <div className="flex justify-center items-center min-h-screen bg-gray-200">
            <div className="bg-white p-8 rounded-xl shadow-lg max-w-md w-full">
                {/* Logo */}
                <div className="text-center mb-8">
                    <img className="mx-auto w-1/2" alt="Logo" src="/YellowLogo.png" />
                    <h2 className="text-xl font-semibold mt-4">Welcome</h2>
                    <p className="text-gray-600 font-sans">Log in to continue</p>
                </div>

                {/* Email Input */}
                <div className="mb-4">
                    <label className="block text-gray-700 mb-1 font-sans">Email address<text className="text-[#D50909]">*</text></label>
                    <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className={`w-full px-4 py-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-black ${errorMessage ? 'border-red-500 focus:ring-red-500' : ''}`}
                        placeholder="Enter your email"
                    />
                </div>

                {/* Password Input */}
                <div className="mb-4">
                    <label className="block text-gray-700 mb-1 font-sans">Password
                        <text className="text-[#D50909]">*</text>
                    </label>
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className={`w-full px-4 py-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-black ${errorMessage ? 'border-red-500 focus:ring-red-500' : ''}`}
                        placeholder="Enter your password"
                    />
                </div>

                {/* Error Message */}
                {errorMessage && (
                    <p className="text-red-500 text-sm flex items-center mb-4 font-sans">
                        <svg className="w-4 h-4 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M18.364 5.636l-7.728 7.728M5.636 5.636l7.728 7.728M5.636 18.364l7.728-7.728m7.728 7.728L5.636 5.636"></path>
                        </svg>
                        {errorMessage}
                    </p>
                )}

                {/* Login Button */}
                <button
                    className="w-full px-6 py-3 bg-black text-white rounded-md shadow hover:bg-[#eae2f8] hover:text-black font-sans"
                    onClick={handleLogin}
                >
                    Continue
                </button>

                {/* Create Profile Link */}
                <div className="text-center mt-4">
                    <p className="text-sm text-gray-500 font-sans">
                        New here?
                        <button
                            className="text-[#FFCC00] hover:underline ml-1"
                            onClick={() => navigate('/signup')}>
                            Create profile
                        </button>
                    </p>
                </div>
            </div>
        </div>
    );
};
