import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { Header } from "../components/Header";
import { Loading } from "../components/Loading";
import { CreateGroup } from "../components/CreateGroup"; // Import the modal component
import Skeleton from 'react-loading-skeleton';
import config from '../config';
import 'react-loading-skeleton/dist/skeleton.css';

export const Groups = () => {
    const navigate = useNavigate();
    const [groups, setGroups] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedGroupMembers, setSelectedGroupMembers] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedGroupExpenses, setSelectedGroupExpenses] = useState({});  // Add this line
    const [page, setPage] = useState(0); // To track which "page" of groups is being loaded
    const [hasMoreGroups, setHasMoreGroups] = useState(true); // To track if there are more groups to load


    // Define the number of groups to load at once
    const GROUPS_PER_PAGE = 3;

    // Fetch groups and members from the backend
    const fetchGroupsAndMembers = async () => {
        try {
            setLoading(true);

            const token = localStorage.getItem('idToken');
            const userId = localStorage.getItem('id');

            // Fetch user-specific groups
            const groupResponse = await fetch(`${config.apiBaseUrl}/api/usergroups/user?userId=${userId}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });

            if (!groupResponse.ok) {
                throw new Error('Failed to fetch groups');
            }

            const groupsData = await groupResponse.json();
            setGroups(groupsData.reverse());  // Reverse order to show latest first

            // Fetch group members and expenses for each group
            const groupMembersPromises = groupsData.map(async (group) => {
                // Fetch group members
                const membersResponse = await fetch(`${config.apiBaseUrl}/api/usergroups/group?groupId=${group.id}`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                });

                if (!membersResponse.ok) {
                    throw new Error(`Failed to fetch members for group ${group.group_name}`);
                }

                const members = await membersResponse.json();

                // Fetch expenses for the group
                const expensesResponse = await fetch(`${config.apiBaseUrl}/api/expenses/getGroupExpenses?groupId=${group.id}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                });

                const groupExpenses = await expensesResponse.json();
                const detailedExpenses = [];

                // Fetch detailed information for each expense (limit to first 5)
                for (let i = 0; i < Math.min(9, groupExpenses.length); i++) {
                    const expense = groupExpenses[i];

                    const expenseDetailResponse = await fetch(`${config.apiBaseUrl}/api/expenses/getExpense?expenseId=${expense.expense_id}`, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`
                        }
                    });

                    if (expenseDetailResponse.ok) {
                        const expenseDetail = await expenseDetailResponse.json();
                        detailedExpenses.push(expenseDetail);
                    }
                }

                return {
                    groupId: group.id,
                    members: members.map(member => member.username),
                    expenses: detailedExpenses // Store detailed expenses for each group
                };
            });

            const groupMembersData = await Promise.all(groupMembersPromises);

            const membersMap = {};
            const expensesMap = {};

            groupMembersData.forEach(({ groupId, members, expenses }) => {
                membersMap[groupId] = members;
                expensesMap[groupId] = expenses; // Map groupId to detailed expenses
            });

            setSelectedGroupMembers(membersMap);
            setSelectedGroupExpenses(expensesMap); // Store expenses

        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchGroupsAndMembers();
    }, []);

    // Navigate to the group details page
    const handleGroupClick = (groupName, groupId) => {
        navigate(`/group/${groupName}`, { state: { groupName, groupId } });
    };

    // if (loading) {
    //     // Render skeleton screens with shimmer effect while loading
    //     return (
    //         <div className="bg-white flex flex-col items-center min-h-screen">
    //             <Header className="w-full" />
    //             <div className="flex flex-col items-center w-full max-w-screen-lg mt-12 px-4">
    //                 <div className="text-4xl font-girassol mb-8">GROUPS</div>

    //                 {/* Skeleton loader for groups */}
    //                 <div className="flex space-x-6" style={{ minWidth: '100%' }}>
    //                     {[1, 2, 3].map(index => (
    //                         <div
    //                             key={index}
    //                             className="bg-gray-300 rounded-[40px] p-4 md:p-6 shadow-lg w-[30%] flex-none cursor-pointer min-h-[500px] md:min-h-[350px]"
    //                         >
    //                             <Skeleton height={30} width={150} />
    //                             <Skeleton count={2} />
    //                             <Skeleton height={40} width={120} />
    //                         </div>
    //                     ))}
    //                 </div>
    //             </div>
    //         </div>
    //     );
    // }



    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <div className="relative bg-white flex flex-col items-center min-h-screen">
            <Header className="w-full" />

            {loading && (
                <Loading />
            )} 

            {/* Groups Section */}
            <div className="flex flex-col items-center w-full max-w-screen-lg mt-12 px-4">
                <div className="text-4xl font-girassol mb-8">GROUPS</div>

                {/* Carousel Wrapper */}
                <div className="overflow-x-auto w-full rounded-[40px]">
                    <div className="flex space-x-6" style={{minWidth: '100%'}}>
                        {groups.map((group, index) => (
                            <div
                                key={index}
                                className="bg-gray-300 rounded-[40px] p-4 md:p-6 shadow-lg flex-none min-h-[500px] md:min-h-[350px] w-full sm:w-1/2 md:w-[30%] lg:w-[30%]"
                                onClick={() => handleGroupClick(group.group_name, group.id)} // Passing group name here
                            >
                                <div className="text-xl md:text-2xl font-bold font-girassol text-center">{group.group_name}</div>
                                <p className="text-sm text-center mt-2 text-gray-600 font-sans">
                                    {selectedGroupMembers[group.id]
                                        ? selectedGroupMembers[group.id].join(", ")
                                        : "No members"}
                                </p>

                                {/* Display first few expenses */}
                                <hr className="mt-1"/>
                                <div className="mt-4 text-center">
                                    {selectedGroupExpenses[group.id] ? (
                                        selectedGroupExpenses[group.id].map((expense, index) => (
                                            <div key={index} className="text-sm mt-2">
                                                <span className="font-semibold">R{expense.total_owed}</span> paid
                                                by {expense.owner.name} for {expense.expense.type}
                                            </div>
                                        ))
                                    ) : (
                                        <p>Loading expenses...</p>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                {/* Create New Group Button */}
                <button
                    className="px-6 py-3 bg-white rounded-md shadow-sm hover:bg-[#eae2f8] mt-6 font-sans"
                    onClick={() => setIsModalOpen(true)}
                >
                    CREATE NEW GROUP
                </button>

                {isModalOpen && (
                    <CreateGroup
                        onClose={() => setIsModalOpen(false)}
                        onCreateGroup={fetchGroupsAndMembers}
                    />
                )}
            </div>
        </div>
    );
};
