import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { FirstPage } from './pages/FirstPage';
import { Login } from './pages/Login';
import { Dashboard } from './pages/Dashboard'; // Import your dashboard component
import { ExpenseOverview } from './pages/ExpenseOverview';
import { Group } from './pages/Group';
import { Groups } from './pages/Groups';
import { Profile } from './pages/Profile';
import { CreateProfile } from './pages/CreateProfile';

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<FirstPage />} />
                <Route path="/login" element={<Login />} />
                <Route path="/signup" element={<CreateProfile />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/expense-overview" element={<ExpenseOverview />} />
                <Route path="/group/:groupname" element={<Group />} /> {/* Dynamic route */}
                <Route path="/groups" element={<Groups />} />
                <Route path="/profile" element={<Profile />} />
            </Routes>
        </Router>
    );
}

export default App;
