import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import config from '../config';

export const CreateProfile = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [username, setUsername] = useState(''); // New state for username
    const [errorMessage, setErrorMessage] = useState('');

    const handleCreateProfile = async () => {
        if (email && password && confirmPassword && username) {
            if (password.length < 6) {
                setErrorMessage('Password must be at least 6 characters long');
                return;
            }

            if (password === confirmPassword) {
                try {
                    // API call to register the user
                    const response = await fetch(`${config.apiBaseUrl}/api/auth/register`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            username,
                            email,
                            password
                        })
                    });
                    const data = await response.json();

                    if (response.ok) {
                        // Navigate to login after successful registration
                        setErrorMessage('');
                        navigate('/login');
                    } else {
                        // Display error message if registration fails
                        setErrorMessage(data.message || 'Registration failed');
                    }
                } catch (error) {
                    setErrorMessage('An error occurred. Please try again.');
                }
            } else {
                setErrorMessage('Passwords do not match');
            }
        } else {
            setErrorMessage('Please fill in all required fields');
        }
    };


    return (
        <div className="flex justify-center items-center min-h-screen bg-gray-200">
            <div className="bg-white p-8 rounded-xl shadow-lg max-w-md w-full">
                {/* Logo */}
                <div className="text-center mb-8">
                    <img className="mx-auto w-1/2" alt="Logo" src="/YellowLogo.png" />
                    <h2 className="text-xl font-semibold mt-4">Create Profile</h2>
                </div>

                {/* Username Input */}
                <div className="mb-4">
                    <label className="block text-gray-700 mb-1 font-sans">Username
                        <text className="text-[#D50909]">*</text>
                    </label>
                    <input
                        type="text"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        className={`w-full px-4 py-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-black ${errorMessage ? 'border-red-500 focus:ring-red-500' : ''}`}
                        placeholder="Enter your username"
                    />
                </div>

                {/* Email Input */}
                <div className="mb-4">
                    <label className="block text-gray-700 mb-1 font-sans">Email address
                        <text className="text-[#D50909]">*</text>
                    </label>
                    <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className={`w-full px-4 py-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-black ${errorMessage ? 'border-red-500 focus:ring-red-500' : ''}`}
                        placeholder="Enter your email"
                    />
                </div>

                {/* Password Input */}
                <div className="mb-4">
                    <label className="block text-gray-700 mb-1 font-sans">Password
                        <text className="text-[#D50909]">*</text>
                    </label>
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className={`w-full px-4 py-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-black ${errorMessage ? 'border-red-500 focus:ring-red-500' : ''}`}
                        placeholder="Create a password"
                    />
                </div>

                {/* Confirm Password Input */}
                <div className="mb-4">
                    <label className="block text-gray-700 mb-1 font-sans">Confirm Password
                        <text className="text-[#D50909]">*</text>
                    </label>
                    <input
                        type="password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        className={`w-full px-4 py-3 border rounded-md focus:outline-none focus:ring-2 focus:ring-black ${errorMessage ? 'border-red-500 focus:ring-red-500' : ''}`}
                        placeholder="Confirm your password"
                    />
                </div>

                {/* Error Message */}
                {errorMessage && (
                    <p className="text-red-500 text-sm flex items-center mb-4 font-sans">
                        <svg className="w-4 h-4 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M18.364 5.636l-7.728 7.728M5.636 5.636l7.728 7.728M5.636 18.364l7.728-7.728m7.728 7.728L5.636 5.636"></path>
                        </svg>
                        {errorMessage}
                    </p>
                )}

                {/* Create Profile Button */}
                <button
                    className="w-full px-6 py-3 bg-black text-white rounded-md shadow hover:bg-[#eae2f8] hover:text-black font-sans"
                    onClick={handleCreateProfile}
                >
                    Create Profile
                </button>

                {/* Back to Login Link */}
                <div className="text-center mt-4">
                    <p className="text-sm text-gray-500 font-sans">
                        Already have an account?
                        <button
                            className="text-[#FFCC00] hover:underline ml-1"
                            onClick={() => navigate('/login')}>
                            Log in here
                        </button>
                    </p>
                </div>
            </div>
        </div>
    );
};
