import React, {useEffect, useRef, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {Header} from "../components/Header"; // Use the existing Header component
import { Loading } from "../components/Loading";
import config from '../config';

export const Group = () => {
    const location = useLocation();
    const {groupId, groupName: initialGroupName} = location.state; // Destructure both groupId and groupName from location.state
    const [groupName, setGroupName] = useState(initialGroupName); // Initialize groupName state with the passed value
    const navigate = useNavigate();

    // State to manage the group members, expenses, and modal visibility
    const [members, setMembers] = useState([]);
    const [newMemberEmail, setNewMemberEmail] = useState("");
    const [isAddMemberModalOpen, setAddMemberIsModalOpen] = useState(false);
    const [isExitGroupModalOpen, setExitGroupIsModalOpen] = useState(false);
    const [isEditGroupModalOpen, setEditGroupIsModalOpen] = useState(false);
    const [isAddExpenseModalOpen, setAddExpenseIsModalOpen] = useState(false);
    const [isDeleteGroupModalOpen, setDeleteGroupModalOpen] = useState(false);
    const [expensesDetails, setExpensesDetails] = useState([]);
    const [newExpenseName, setNewExpenseName] = useState(''); // Store the new name
    const [isEditingName, setIsEditingName] = useState(false); // For edit mode
    const [loading, setLoading] = useState(true);

    const [expenseType, setExpenseType] = useState("");
    const [expenseAmount, setExpenseAmount] = useState("");
    const [splitMethod, setSplitMethod] = useState("specific");
    const [expenseDescription, setExpenseDescription] = useState("");
    const [isExpenseModalOpen, setIsExpenseModalOpen] = useState(false); // New state for the expense modal
    const [isEditGroupNameModalOpen, setEditGroupNameModalOpen] = useState(false);
    const [newGroupName, setNewGroupName] = useState(groupName);
    const [selectedExpense, setSelectedExpense] = useState(null); // Stores the selected expense
    const [isAddBalanceMode, setIsAddBalanceMode] = useState(false);
    const [isPayDebtMode, setIsPayDebtMode] = useState(false);
    const [selectedMembers, setSelectedMembers] = useState([]); // State to store selected members
    const [selectAll, setSelectAll] = useState(false); // State to track "All Group Members" selection
    const currentUser = localStorage.getItem("username");

    const [selectedReceipt, setSelectedReceipt] = useState(null);
    const [selectedPOP, setSelectedPOP] = useState(null);


    const fetchExpensesDetails = async () => {
        try {
            setLoading(true);
            const token = localStorage.getItem('idToken');

            // Fetch group expenses
            const groupExpensesResponse = await fetch(`${config.apiBaseUrl}/api/expenses/getGroupExpenses?groupId=${groupId}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (!groupExpensesResponse.ok) {
                console.error('Failed to fetch group expenses');
                return;
            }

            const groupExpensesData = await groupExpensesResponse.json();

            // Get details for each expense and calculate what the current user owes
            const expenseDetailsPromises = groupExpensesData.map(async (expense) => {
                const expenseDetailResponse = await fetch(`${config.apiBaseUrl}/api/expenses/getExpense?expenseId=${expense.expense_id}`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                });

                if (!expenseDetailResponse.ok) {
                    throw new Error(`Failed to fetch details for expense ID ${expense.expense_id}`);
                }

                return await expenseDetailResponse.json();
            });

            const allExpenseDetails = await Promise.all(expenseDetailsPromises);
            setExpensesDetails(allExpenseDetails); // Set the fetched details in state

        } catch (error) {
            console.error('Error fetching expense details:', error);
        } finally {
            setLoading(false);
        }
    };

    // Call this function when the component mounts to fetch all expense details
    useEffect(() => {
        fetchGroupMembers();
        fetchExpensesDetails();
    }, [groupId]);

    const fetchGroupMembers = async () => {
        try {
            setLoading(true);
            const token = localStorage.getItem('idToken');
            const response = await fetch(`${config.apiBaseUrl}/api/usergroups/group?groupId=${groupId}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                console.error('Failed to fetch group members');
                return;
            }

            const membersData = await response.json();
            setMembers(membersData);
        } catch (error) {
            console.error('Error fetching members:', error);
        } finally {
            setLoading(false);
        }
    };


    const handleDeleteGroup = async () => {
        try {
            setLoading(true);
            const token = localStorage.getItem('idToken');
            const response = await fetch(`${config.apiBaseUrl}/api/groups/delete?id=${groupId}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                const errorDetails = await response.text();
                console.error('Failed to delete group:', errorDetails);
                console.log(`${config.apiBaseUrl}/api/groups/delete?id=${groupId}`);
                return;
            }

            console.log('Group deleted successfully');
            setDeleteGroupModalOpen(false);
            navigate('/dashboard');

        } catch (error) {
            console.error('Error deleting group:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleExitGroup = async () => {
        try {
            setLoading(true);
            const token = localStorage.getItem('idToken');
            const userId = localStorage.getItem('id');
            const response = await fetch(`${config.apiBaseUrl}/api/usergroups/leave?userId=${userId}&groupId=${groupId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                const errorDetails = await response.text();
                console.error('Failed to exit group:', errorDetails);
                console.log(`${config.apiBaseUrl}/api/usergroups/leave?userId=${userId}`);
                return;
            }

            console.log('Group exited successfully');
            setExitGroupIsModalOpen(false);
            navigate('/dashboard');

        } catch (error) {
            console.error('Error deleting group:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleAddMember = async () => {
        try {
            setLoading(true);
            const token = localStorage.getItem('idToken');
            const response = await fetch(`${config.apiBaseUrl}/api/usergroups/email?email=${newMemberEmail}&groupId=${groupId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                console.error('Failed to add member');
                return;
            }

            console.log('Member added successfully');
            setNewMemberEmail(""); // Clear the email field
            setAddMemberIsModalOpen(false); // Close the modal
            await fetchGroupMembers(); // Refresh members

        } catch (error) {
            console.error('Error adding member:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleSaveExpense = async () => {
        try {
            setLoading(true);
            const token = localStorage.getItem('idToken');
            const userId = localStorage.getItem('id');

            if (!expenseType || !expenseAmount || !expenseDescription) {
                console.error('Please fill in all fields before saving.');
                return;
            }

            // Call the API to create the new expense
            const response = await fetch(
                `${config.apiBaseUrl}/api/expenses?name=${expenseDescription}&type=${expenseType}&group_id=${groupId}&owner_id=${userId}`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                }
            );

            if (!response.ok) {
                console.error('Failed to create expense');
                return;
            }

            console.log('Expense added successfully');
            const expensesData = await response.json();
            console.log(expensesData);

            const userIdsParam = selectedMembers.join(",");

            // Call the API to add balance to the new expense
            const response_ = await fetch(
                `${config.apiBaseUrl}/api/userexpenses/addBalance?expense_id=${expensesData.expense_id}&amount=${expenseAmount}&splitMethod=${splitMethod}&userIds=${userIdsParam}`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    }
                }
            );

            if (!response_.ok) {
                console.error('Failed to add balance');
                return;
            }
            console.log('Balance added successfully');
            console.log(`${config.apiBaseUrl}/api/userexpenses/addBalance?expense_id=${expensesData.expense_id}&amount=${expenseAmount}&splitMethod=${splitMethod}&userIds=${userIdsParam}`);

            setAddExpenseIsModalOpen(false);
            setExpenseType("");
            setExpenseAmount("");
            setExpenseDescription("");

            await fetchExpensesDetails(); // Refresh expenses details

        } catch (error) {
            console.error('Error adding expense:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleAddBalance = async (amount) => {
        try {
            setLoading(true);
            console.log("Adding balance for:", selectedExpense);
            console.log("Amount:", amount);
            const token = localStorage.getItem('idToken');

            if (amount === 0 || !selectedExpense || !selectedExpense.expense.expense_id) {
                alert("Invalid amount or expense.");
                return;
            }

            const userIdsParam = selectedMembers.join(",");

            console.log(userIdsParam);

            const response = await fetch(
                `${config.apiBaseUrl}/api/userexpenses/addBalance?expense_id=${selectedExpense.expense.expense_id}&amount=${amount}&splitMethod=${splitMethod}&userIds=${userIdsParam}`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                }
            );

            if (!response.ok) {
                console.error('Failed to add balance to the expense');
                return;
            }

            console.log('Balance added successfully');
            closeExpenseModal(); // Close the modal
            await fetchExpensesDetails(); // Refresh expenses details
        } catch (error) {
            console.error('Error adding balance:', error);
        } finally {
            setLoading(false);
        }
    };

    // Function to handle paying off debt for the selected expense
    const handlePayDebtForExpense = async (amount) => {
        try {
            setLoading(true);
            const token = localStorage.getItem('idToken');
            const userId = localStorage.getItem('id');

            if (amount === 0) {
                alert("Invalid amount.");
                return;
            }

            const response = await fetch(
                `${config.apiBaseUrl}/api/userexpenses/payDebt?user_id=${userId}&expense_id=${selectedExpense.expense.expense_id}&amount=${amount}`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                }
            );

            if (!response.ok) {
                console.error('Failed to pay debt for the expense');
                return;
            }

            console.log('Debt paid successfully');
            closeExpenseModal(); // Close the modal
            await fetchExpensesDetails(); // Refresh expenses details
        } catch (error) {
            console.error('Error paying debt:', error);
        } finally {
            setLoading(false);
        }
    };

    // Function to handle deleting the selected expense
    const handleDeleteExpense = async () => {
        try {
            setLoading(true);
            const token = localStorage.getItem('idToken');

            const response = await fetch(
                `${config.apiBaseUrl}/api/expenses/delete?id=${selectedExpense.expense.expense_id}`,
                {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                }
            );

            if (!response.ok) {
                console.error('Failed to delete the expense');
                return;
            }

            console.log('Expense deleted successfully');
            closeExpenseModal(); // Close the modal
            await fetchExpensesDetails(); // Refresh expenses details
        } catch (error) {
            console.error('Error deleting expense:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleSaveNewExpenseName = async (expenseId, updatedName) => {
        try {
            setLoading(true);
            const token = localStorage.getItem('idToken');
            const response = await fetch(`${config.apiBaseUrl}/api/expenses/changeName?id=${expenseId}&name=${encodeURIComponent(updatedName)}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error("Failed to update the expense name");
            }

            // Update the UI with the new name after successful response
            const updatedExpense = { ...selectedExpense, expense: { ...selectedExpense.expense, name: updatedName } };
            setSelectedExpense(updatedExpense); // Update the current expense name in the modal
            await fetchExpensesDetails(); // Refresh the expense list
        } catch (error) {
            console.error("Error updating expense name:", error);
        } finally {
            setLoading(false);
        }
    };


    const handleUpdateGroupName = async () => {
        try {
            setLoading(true);
            const token = localStorage.getItem('idToken');
            const response = await fetch(`${config.apiBaseUrl}/api/groups/changeName?id=${groupId}&name=${newGroupName}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                console.error('Failed to update group name');
                return;
            }

            console.log('Group name updated successfully');
            setGroupName(newGroupName);
        } catch (error) {
            console.error('Error updating group name:', error);
        } finally {
            setLoading(false);
        }
    };


    const handleCheckboxChange = (memberId) => {
        // Prevent individual checkboxes from being changed if "All Group Members" is selected
        if (selectAll) {
            return; // Do nothing if "All Group Members" is checked
        }

        setSelectedMembers((prevSelected) => {
            if (prevSelected.includes(memberId)) {
                // If already selected, remove from the array
                return prevSelected.filter((id) => id !== memberId);
            } else {
                // If not selected, add to the array
                return [...prevSelected, memberId];
            }
        });
    };

    const handleSelectAll = () => {
        if (selectAll) {
            // If "All" is selected, deselect all members
            setSelectedMembers([]);
            setSelectAll(false);
        } else {
            // If "All" is not selected, select all members
            const allMemberIds = members.map((member) => member.userId);
            setSelectedMembers(allMemberIds);
            setSelectAll(true);
        }
    };


    const handleExpenseClick = (expense) => {
        console.log("Clicked Expense:", expense);  // Debugging statement
        setSelectedExpense(expense);
        setNewExpenseName(expense.expense.name);
        setIsExpenseModalOpen(true);
    };

    // Modals Toggle Functions
    const toggleAddMemberModal = () => setAddMemberIsModalOpen(!isAddMemberModalOpen);
    const toggleExitGroupModal = () => setExitGroupIsModalOpen(!isExitGroupModalOpen);
    const toggleEditGroupModal = () => setEditGroupIsModalOpen(!isEditGroupModalOpen);
    const toggleAddExpenseModal = () => setAddExpenseIsModalOpen(!isAddExpenseModalOpen);
    const toggleDeleteGroupModal = () => setDeleteGroupModalOpen(!isDeleteGroupModalOpen);

    const closeExpenseModal = () => {
        setIsAddBalanceMode(false);
        setIsExpenseModalOpen(false);
    };

    const openAddBalanceModal = () => {
        setIsAddBalanceMode(true);
        setExpenseAmount(0);
    }

    const openPayDebtModal = () => {
        setIsPayDebtMode(true);
        setExpenseAmount(findUserAmountOwed(selectedExpense.user_expenses, currentUser));
    }

    const findUserAmountOwed = (userExpenses, currentUser) => {
        const userExpense = userExpenses.find(user => user.name === currentUser); // or user.email === currentUser if you use email
        return userExpense ? userExpense.amount_owed : 0;
    };

    //file upload functionality
    const fileInputRef = useRef(null);

    const handleUploadButtonClick = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = async (event, fileType) => {
        const file = event.target.files[0];
        if (file) {
            if (fileType === "POP") {
                setSelectedPOP(file);
            } else if (fileType === "receipt") {
                setSelectedReceipt(file);
            }
        }
    };


    return (
        <div className="relative bg-[#f6f6f6] min-h-screen flex flex-col items-center">
            <Header className="w-full"/>

            {loading && (
                <Loading />
            )} 

            <div className="w-full max-w-screen-xl px-4 mt-8">
                <div className="w-full max-w-screen-lg mx-auto p-4 md:p-8">
                    <div className="text-center mb-8">
                        <h1 className="text-3xl font-bold">{groupName}</h1>
                        <p className="text-lg mt-2 font-sans">
                            {members.length > 0
                                ? members.map(member => member.username).join(", ")
                                : "No members available"}
                        </p>
                    </div>

                    <div className="flex justify-center flex-wrap space-x-4 mb-8">
                        <button
                            className="mt-2 px-6 py-2 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-[#eae2f8]  font-sans"
                            onClick={toggleAddMemberModal}>
                            ADD MEMBER
                        </button>
                        <button
                            className="mt-2 px-6 py-2 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-[#eae2f8] font-sans"
                            onClick={toggleExitGroupModal}>
                            EXIT GROUP
                        </button>
                        <button
                            className="mt-2 px-6 py-2 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-[#eae2f8] font-sans"
                            onClick={() => setEditGroupNameModalOpen(true)}>
                            EDIT GROUP
                        </button>
                        <button
                            className="mt-2 px-6 py-2 bg-red-600 text-white border rounded-md shadow-sm hover:bg-red-500 font-sans"
                            onClick={toggleDeleteGroupModal}>
                            DELETE GROUP
                        </button>
                    </div>

                    {/* Expenses List */}
                    <div className="bg-white rounded-xl shadow-lg p-4 md:p-6">
                        <div className="flex justify-between items-center mb-4">
                            <h2 className="text-2xl font-semibold">EXPENSES</h2>
                            <h2 className="text-2xl font-semibold">YOU OWE</h2>
                        </div>
                        <ul className="divide-y divide-gray-300">
                            {expensesDetails.map((expenseDetail) => (
                                <li key={expenseDetail.expense.expense_id} className="py-4 cursor-pointer"
                                    onClick={() => handleExpenseClick(expenseDetail)}>
                                    <div className="flex justify-between items-center">
                                        <div>
                                            <span
                                                className="text-lg font-bold">R{expenseDetail.total_owed}</span> for {expenseDetail.expense.type} ({expenseDetail.expense.name})
                                            <p className="text-sm">Created by: {expenseDetail.owner.name}</p>
                                        </div>
                                        <span
                                            className="text-lg font-bold">R{findUserAmountOwed(expenseDetail.user_expenses, currentUser)}</span>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>

                    {/* Bottom Buttons */}
                    <div className="flex justify-center space-x-4 mt-8">
                        <button
                            className="px-6 py-3 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-[#eae2f8] font-sans"
                            onClick={toggleAddExpenseModal}>
                            ADD EXPENSE
                        </button>
                    </div>
                </div>
            </div>

            {/* Expense Modal */}
            {isExpenseModalOpen && selectedExpense && (
                <div
                    className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 backdrop-filter backdrop-blur-sm"
                    onClick={closeExpenseModal}
                >
                    <div className="bg-[#eae2f8] rounded-lg p-6 max-w-xl w-full relative"
                         onClick={(e) => e.stopPropagation()}>
                        {isAddBalanceMode ? (
                            // Add Balance UI
                            <>
                                <h2 className="text-3xl font-semibold mb-6">Add Balance</h2>
                                <div className="flex flex-col items-center mb-6">
                                    <p className="text-xl font-bold mb-4">
                                        Total Amount:
                                        R{(parseFloat(selectedExpense.total_owed) + parseFloat(expenseAmount)).toFixed(2)}
                                    </p>
                                    <div className="flex items-center space-x-6 mt-4">
                                        <button
                                            className="text-black text-6xl font-bold"
                                            onClick={() => {
                                                setExpenseAmount((prev) => (parseFloat(prev) - 1).toFixed(2));
                                            }}
                                        >
                                            -
                                        </button>
                                        <input
                                            value={expenseAmount}
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                const decimalValue = value.match(/^\d*(\.\d{0,2})?$/);
                                                if (decimalValue) {
                                                    setExpenseAmount(decimalValue[0]);
                                                }
                                            }}
                                            className="w-20 text-center text-3xl font-bold rounded-md bg-[#eae2f8]"
                                            type="text"
                                        />
                                        <button
                                            className="text-black text-6xl font-bold"
                                            onClick={() => {
                                                setExpenseAmount((prev) => (parseFloat(prev) + 1).toFixed(2));
                                            }}
                                        >
                                            +
                                        </button>
                                    </div>

                                    {/* Left-aligned Split Between section */}
                                    <div className="w-full text-left mt-6">
                                        <label className="block font-medium font-sans text-md">Split between*</label>
                                        <div className="mt-1 block w-full border border-gray-300 bg-gray-100 rounded-md p-4">
                                            {/* "All Group Members" Checkbox */}
                                            <div className="flex items-center space-x-3 mb-2">
                                                <input
                                                    type="checkbox"
                                                    id="select-all"
                                                    checked={selectAll}
                                                    onChange={handleSelectAll}
                                                    className="form-checkbox"
                                                />
                                                <label htmlFor="select-all" className="text-sm font-bold">
                                                    All Group Members
                                                </label>
                                            </div>

                                            <hr className="mb-2"/>

                                            {/* Dynamically generate checkboxes based on group members */}
                                            {members
                                                .sort((a, b) => (a.username === currentUser ? -1 : b.username === currentUser ? 1 : 0))
                                                .map((member) => (
                                                    <div key={member.userId}
                                                         className="flex items-center space-x-3 mb-2">
                                                        <input
                                                            type="checkbox"
                                                            id={member.userId}
                                                            checked={selectAll || selectedMembers.includes(member.userId)}
                                                            onChange={() => handleCheckboxChange(member.userId)}
                                                            disabled={selectAll}
                                                            className="form-checkbox"
                                                        />
                                                        <label htmlFor={member.userId} className="text-sm font-medium">
                                                            {member.username} {member.username === currentUser && (
                                                            <span className="text-blue-500 font-bold">(YOU)</span>
                                                        )}
                                                        </label>
                                                    </div>
                                                ))}
                                        </div>
                                    </div>
                                </div>

                                {/* Modal Buttons */}
                                <div className="flex justify-end space-x-4">
                                    <button
                                        className="px-4 py-2 bg-black text-white text-lg font-bold rounded-md font-sans"
                                        onClick={async () => {
                                            await handleAddBalance(expenseAmount);
                                            setIsAddBalanceMode(false);
                                            closeExpenseModal(); // Save and close
                                        }}
                                    >
                                        SAVE
                                    </button>
                                    <button
                                        className="px-4 py-2 bg-gray-300 text-black text-lg font-bold rounded-md font-sans"
                                        onClick={() => {
                                            setIsAddBalanceMode(false);
                                            // closeExpenseModal(); // Cancel and close
                                        }}
                                    >
                                        CANCEL
                                    </button>
                                </div>
                            </>
                        ) : isPayDebtMode ? (
                            // Pay Debt UI
                            <>
                                {/* Pay Debt UI */}
                                <h2 className="text-3xl font-semibold mb-6">PayDebt</h2>
                                <div className="flex flex-col items-center mb-6">
                                    <p className="text-xl font-bold mb-4">
                                        Amount Owing after Payment:
                                        R{findUserAmountOwed(selectedExpense.user_expenses, currentUser) - expenseAmount}
                                    </p>
                                    <div className="flex items-center space-x-6 mt-4">
                                        <button
                                            className=" text-black text-6xl font-bold "
                                            onClick={() => {
                                                setExpenseAmount((prev) => (Math.max(parseFloat(prev) - 1, 0)).toFixed(2));
                                            }}
                                        >
                                            -
                                        </button>
                                        <input
                                            value={expenseAmount}
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                const decimalValue = value.match(/^\d*(\.\d{0,2})?$/); // Allow up to 2 decimal places
                                                if (decimalValue) {
                                                    setExpenseAmount(decimalValue[0]);
                                                }
                                            }}
                                            className="w-20 text-center text-3xl font-bold rounded-md bg-[#eae2f8]"
                                            type="text" // Ensure input type is text to control the formatting
                                        />
                                        <button
                                            className="text-black text-6xl font-bold "
                                            onClick={() => {
                                                const maxOwed = findUserAmountOwed(selectedExpense.user_expenses, currentUser);
                                                setExpenseAmount((prev) => {
                                                    const newAmount = parseFloat(prev) + 1;
                                                    return (Math.min(newAmount, maxOwed)).toFixed(2); // Ensure we don't exceed the amount owed
                                                });
                                            }}
                                        >
                                            +
                                        </button>
                                    </div>
                                </div>

                                {/* Modal Buttons */}
                                <div className="flex justify-end space-x-4">
                                    <button
                                        className="px-4 py-2 bg-black text-white text-lg font-bold rounded-md font-sans"
                                        onClick={async () => {
                                            await handlePayDebtForExpense(expenseAmount);
                                            setIsPayDebtMode(false);
                                            closeExpenseModal(); // Save and close
                                        }}
                                    >
                                        SAVE
                                    </button>
                                    <button
                                        className="px-4 py-2 bg-gray-300 text-black text-lg font-bold rounded-md font-sans"
                                        onClick={() => {
                                            setIsPayDebtMode(false); // Reset the balance mode
                                            // closeExpenseModal(); // Cancel and close
                                        }}
                                    >
                                        CANCEL
                                    </button>
                                </div>
                            </>
                        ) : (
                            // Default Manage Expense UI
                            <>
                                <div
                                    className="fixed inset-0 bg-black bg-opacity-20 flex justify-center items-center z-50 backdrop-filter backdrop-blur-sm">
                                    <div className="bg-[#eae2f8] shadow-lg rounded-lg p-6 w-full max-w-lg relative"
                                         onClick={(e) => e.stopPropagation()}>
                                        <h2 className="text-2xl font-semibold mb-6 text-center">Manage Expense</h2>

                                        <div className="bg-gray-100 p-4 rounded-md mb-6">
                                            {isEditingName ? (
                                                <div className="flex items-center space-x-2">
                                                    <input
                                                        type="text"
                                                        value={newExpenseName}
                                                        onChange={(e) => setNewExpenseName(e.target.value)}
                                                        className="border border-gray-300 rounded-md px-2 py-1 w-full"
                                                    />
                                                    <button
                                                        className="text-blue-500 font-semibold"
                                                        onClick={async () => {
                                                            await handleSaveNewExpenseName(selectedExpense.expense.expense_id, newExpenseName);
                                                            setIsEditingName(false);
                                                        }}
                                                    >
                                                        Save
                                                    </button>
                                                    <button
                                                        className="text-gray-500 font-semibold"
                                                        onClick={() => setIsEditingName(false)}
                                                    >
                                                        Cancel
                                                    </button>
                                                </div>
                                            ) : (
                                                <div className="flex justify-between items-center">
                                                    <p className="text-2xl font-semibold mb-1 ">{selectedExpense.expense.name}</p>
                                                    <button
                                                        className="text-blue-500 text-sm underline"
                                                        onClick={() => setIsEditingName(true)}
                                                    >
                                                        Edit
                                                    </button>
                                                </div>
                                            )}

                                            <hr className="mb-1"/>

                                            <p className="text-sm text-gray-600 mb-1">Created
                                                by: {selectedExpense.owner.name}</p>
                                            <p className="text-sm text-gray-600 mb-1">Type: {selectedExpense.expense.type}</p>
                                            <p className="text-sm text-gray-600 mb-1">Total Amount: <span
                                                className="font-semibold">R{selectedExpense.total_owed}</span></p>
                                            <p className="text-sm text-gray-600">Amount Owed by You: <span
                                                className="font-semibold">R{findUserAmountOwed(selectedExpense.user_expenses, currentUser)}</span>
                                            </p>

                                            <hr className="mt-4 mb-4"/>

                                            {/* Loop through all group members and show what they owe */}
                                            {members.map((member) => {
                                                if (member.username === currentUser) {
                                                    return null; // Skip rendering if it's the current user
                                                }

                                                // Check if the current member is in the user_expenses list
                                                const userExpense = selectedExpense.user_expenses.find(user => user.name === member.username);
                                                const amountOwed = userExpense ? parseFloat(userExpense.amount_owed) : 0; // Ensure amountOwed is a valid number, default to 0 if undefined

                                                return (
                                                    <p key={member.userId} className="text-sm text-gray-600">
                                                        {member.username} owes: <span className="font-semibold">R{amountOwed.toFixed(2)}</span>
                                                    </p>
                                                );
                                            })}
                                        </div>

                                        {/* Buttons in a Single Line */}
                                        <div className="flex justify-between space-x-2">
                                            <button
                                                className={`flex-1 py-2 rounded-lg text-white  font-semibold ${
                                                    selectedExpense.owner.name !== currentUser ? 'bg-gray-300 cursor-not-allowed' : 'bg-black hover:bg-blue-600'
                                                }`}
                                                onClick={openAddBalanceModal}
                                                disabled={selectedExpense.owner.name !== currentUser}
                                                title={
                                                    selectedExpense.owner.name.trim().toLowerCase() !== currentUser.trim().toLowerCase()
                                                        ? 'Only the owner can add balance to this expense'
                                                        : ''
                                                }
                                            >

                                                Add Balance
                                            </button>

                                            <button
                                                className={`flex-1 py-2 rounded-lg text-white font-semibold ${
                                                    parseFloat(findUserAmountOwed(selectedExpense.user_expenses, currentUser)) === 0
                                                        ? 'bg-gray-300 cursor-not-allowed' : 'bg-black hover:bg-green-600'
                                                }`}
                                                onClick={openPayDebtModal}
                                                disabled={parseFloat(findUserAmountOwed(selectedExpense.user_expenses, currentUser)) === 0}
                                                title={
                                                    parseFloat(findUserAmountOwed(selectedExpense.user_expenses, currentUser)) === 0
                                                        ? 'You do not owe anything for this expense'
                                                        : ''
                                                }
                                            >
                                                Pay Debt
                                            </button>

                                            <button
                                                className="flex-1 py-2 bg-red-500 hover:bg-red-600 text-white font-semibold rounded-lg"
                                                onClick={handleDeleteExpense}>
                                                Delete
                                            </button>

                                            <button
                                                className="flex-1 py-2 bg-gray-300 hover:bg-gray-400 text-black font-semibold rounded-lg"
                                                onClick={closeExpenseModal}>
                                                Cancel
                                            </button>
                                        </div>
                                    </div>
                                </div>

                            </>
                        )}
                    </div>
                </div>
            )}

            {/* Add Expense Modal */}
            {isAddExpenseModalOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 backdrop-filter backdrop-blur-sm"
                     onClick={toggleAddExpenseModal}
                >
                    <div className="bg-[#eae2f8] rounded-lg p-6 max-w-lg w-full relative shadow-lg"
                         onClick={(e) => e.stopPropagation()}
                    >
                        <h2 className="text-2xl font-semibold mb-4 text-center">Add Expense</h2>

                        <div className="grid grid-cols-2 gap-4">
                            {/* Expense Type */}
                            <div>
                                <label className="block text-sm font-medium">Type *</label>
                                <select
                                    value={expenseType}
                                    onChange={(e) => setExpenseType(e.target.value)}
                                    className="mt-1 block w-full border border-gray-300 rounded-md p-2 focus:outline-none "
                                >
                                    <option value="">SELECT</option>
                                    <option value="Food">Food</option>
                                    <option value="Transport">Transport</option>
                                    <option value="Rent">Rent</option>
                                    <option value="Utilities">Utilities</option>
                                    <option value="Groceries">Groceries</option>
                                    <option value="Travel">Travel</option>
                                    <option value="Dining Out">Dining Out</option>
                                    <option value="Entertainment">Entertainment</option>
                                    <option value="Transportation">Transportation</option>
                                    <option value="Household Supplies">Household Supplies</option>
                                    <option value="Internet">Internet</option>
                                    <option value="Petrol">Petrol</option>
                                    <option value="Insurance">Insurance</option>
                                    <option value="Repairs">Repairs</option>
                                    <option value="Gifts">Gifts</option>
                                    <option value="Sundry">Sundry</option>

                                    {/* Add other options here as needed */}
                                </select>
                            </div>

                            {/* Expense Amount */}
                            <div>
                                <label className="block text-sm font-medium">Amount *</label>
                                <input
                                    type="number"
                                    value={expenseAmount}
                                    onChange={(e) => setExpenseAmount(e.target.value)}
                                    className="mt-1 block w-full border border-gray-300 rounded-md p-2 focus:outline-none"
                                    placeholder="R"
                                />
                            </div>

                            {/* Split Between */}
                            <div className="col-span-2">
                                <label className="block text-sm font-medium">Split between *</label>
                                <div className="bg-gray-100 p-4 rounded-lg border border-gray-300">
                                    {/* "All Group Members" Checkbox */}
                                    <div className="flex items-center space-x-3 mb-2">
                                        <input
                                            type="checkbox"
                                            id="select-all"
                                            checked={selectAll}
                                            onChange={handleSelectAll}
                                            className="form-checkbox"
                                        />
                                        <label htmlFor="select-all" className="text-sm font-medium">
                                            All Group Members
                                        </label>
                                    </div>

                                    <hr className="mb-2"/>

                                    {/* Dynamically generate checkboxes based on group members */}
                                    {members
                                        .sort((a, b) => (a.username === currentUser ? -1 : b.username === currentUser ? 1 : 0))
                                        .map((member) => (
                                            <div key={member.userId} className="flex items-center space-x-3 mb-2">
                                                <input
                                                    type="checkbox"
                                                    id={member.userId}
                                                    checked={selectAll || selectedMembers.includes(member.userId)}
                                                    onChange={() => handleCheckboxChange(member.userId)}
                                                    disabled={selectAll}
                                                    className="form-checkbox"
                                                />
                                                <label htmlFor={member.userId} className="text-sm font-medium">
                                                    {member.username} {member.username === currentUser && (
                                                    <span className="text-blue-500 font-bold">(YOU)</span>
                                                )}
                                                </label>
                                            </div>
                                        ))}
                                </div>
                            </div>

                            {/* Upload Receipt */}
                            <div className="col-span-2">
                                <label className="block text-sm font-medium">Upload receipt</label>
                                <button
                                    className="mt-1 block w-full border border-gray-300 rounded-md px-4 py-2 bg-gray-50 hover:bg-gray-100 focus:ring-2 focus:ring-indigo-500"
                                    onClick={handleUploadButtonClick}
                                >
                                    OPEN FILE
                                </button>
                                <input
                                    type="file"
                                    ref={fileInputRef}
                                    onChange={(event) => handleFileChange(event, "receipt")}
                                    style={{ display: 'none' }}
                                />
                                {selectedReceipt && <p className="mt-2 text-sm text-gray-500">Selected File: {selectedReceipt.name}</p>}
                            </div>

                            {/* Description */}
                            <div className="col-span-2">
                                <label className="block text-sm font-medium">Description *</label>
                                <textarea
                                    value={expenseDescription}
                                    onChange={(e) => setExpenseDescription(e.target.value)}
                                    className="mt-1 block w-full border border-gray-300 rounded-md p-2 focus:outline-none "
                                    placeholder="Description"
                                />
                            </div>
                        </div>

                        {/* Modal Buttons */}
                        <div className="flex justify-end space-x-4 mt-6">
                            <button
                                className="px-4 py-2 bg-black text-white rounded-md font-semibold focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                onClick={handleSaveExpense}
                            >
                                SAVE
                            </button>
                            <button
                                className="px-4 py-2 bg-gray-300 text-black rounded-md font-semibold hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-400"
                                onClick={toggleAddExpenseModal}
                            >
                                CANCEL
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {/* Delete Group Modal */}
            {isDeleteGroupModalOpen && (
                <div
                    className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 backdrop-filter backdrop-blur-sm"
                    onClick={toggleDeleteGroupModal}
                >
                    <div className="bg-[#eae2f8] rounded-lg p-6 max-w-lg w-full relative"
                         onClick={(e) => e.stopPropagation()}>
                        <label className="block text-sm font-medium font-sans">Are you sure you want to delete this
                            group?</label>
                        <br/>

                        {/* Modal Buttons */}
                        <div className="flex justify-end space-x-4">
                            <button
                                className="px-4 py-2 bg-red-600 text-white rounded-md font-sans"
                                onClick={handleDeleteGroup} // Handle the group deletion
                            >
                                DELETE GROUP
                            </button>
                            <button className="px-4 py-2 bg-gray-300 text-black rounded-md font-sans"
                                    onClick={toggleDeleteGroupModal}>
                                CANCEL
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {/* Delete Group Modal */}
            {isExitGroupModalOpen && (
                <div
                    className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 backdrop-filter backdrop-blur-sm"
                    onClick={toggleExitGroupModal}
                >
                    <div className="bg-[#eae2f8] rounded-lg p-6 max-w-lg w-full relative"
                         onClick={(e) => e.stopPropagation()}>
                        <label className="block text-sm font-medium font-sans">Are you sure you want to leave group
                            group?</label>
                        <br/>

                        {/* Modal Buttons */}
                        <div className="flex justify-end space-x-4">
                            <button
                                className="px-4 py-2 bg-red-600 text-white rounded-md font-sans"
                                onClick={handleExitGroup} // Handle the group deletion
                            >
                                EXIT GROUP
                            </button>
                            <button className="px-4 py-2 bg-gray-300 text-black rounded-md font-sans"
                                    onClick={toggleExitGroupModal}>
                                CANCEL
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {/* Edit Group Modal */}
            {isEditGroupNameModalOpen && (
                <div
                    className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 backdrop-filter backdrop-blur-sm"
                    onClick={() => setEditGroupNameModalOpen(false)}
                >
                    <div
                        className="bg-[#eae2f8] rounded-lg p-6 max-w-lg w-full relative"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <h2 className="text-2xl font-semibold mb-4">Edit Group Name</h2>
                        <input
                            type="text"
                            value={newGroupName}
                            onChange={(e) => setNewGroupName(e.target.value)}
                            className="mt-1 block w-full border border-gray-300 rounded-md"
                            placeholder="Enter new group name"
                        />

                        <div className="flex justify-end space-x-4 mt-4">
                            <button
                                className="px-4 py-2 bg-black text-white rounded-md font-sans"
                                onClick={async () => {
                                    await handleUpdateGroupName();
                                    setEditGroupNameModalOpen(false);
                                }}
                            >
                                SAVE
                            </button>
                            <button
                                className="px-4 py-2 bg-gray-300 text-black rounded-md font-sans"
                                onClick={() => setEditGroupNameModalOpen(false)}
                            >
                                CANCEL
                            </button>
                        </div>
                    </div>
                </div>
            )}


            {/* Add Member Modal */}
            {isAddMemberModalOpen && (
                <div
                    className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 backdrop-filter backdrop-blur-sm"
                    onClick={toggleAddMemberModal}
                >
                    <div className="bg-[#eae2f8] rounded-lg p-6 max-w-lg w-full relative"
                         onClick={(e) => e.stopPropagation()}>
                        <label className="block text-sm font-medium font-sans">Add anyone to join your group using their
                            email:</label>
                        <br/>
                        <input
                            type="text"
                            value={newMemberEmail}
                            onChange={(e) => setNewMemberEmail(e.target.value)} // Capture the email input
                            className="mt-1 block w-full border border-gray-300 rounded-md"
                            placeholder="example@gmail.com"
                        />
                        <br/>

                        {/* Modal Buttons */}
                        <div className="flex justify-end space-x-4">
                            <button
                                className="px-4 py-2 bg-black text-white rounded-md font-sans"
                                onClick={handleAddMember} // Handle the member addition
                            >
                                ADD TO GROUP
                            </button>
                            <button className="px-4 py-2 bg-gray-300 text-black rounded-md font-sans"
                                    onClick={toggleAddMemberModal}>
                                CANCEL
                            </button>
                        </div>
                    </div>
                </div>
            )}

        </div>


    );
};